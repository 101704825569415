
import {combineReducers} from "redux";
import adminData from "./adminData";
import appState from "./appState";
import entities from "./entities";
import search from "./search";
import site from "./site";
import user from "./user";
import signUp from "./signUp";

export default combineReducers({
    appState,
    adminData,
    entities,
    search,
    site,
    user,
    signUp
});
