exports = module.exports = require("../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".Input__root{width:100%;position:relative}.Input__root input{width:100%}.Input__root input.error{font-weight:400;font-size:1.1rem}.Input__root textarea.Input__noResize{resize:none}.Input__root.Input__error{color:#db392e}", "", {"version":3,"sources":["/tmp/build_e958b763/web/js/components/shared/elements/Input.scss"],"names":[],"mappings":"AAAA,aAAM,WAAW,iBAAiB,CAAC,mBAAY,UAAU,CAAC,yBAA2B,gBAAmB,gBAAgB,CAAC,sCAAwB,WAAW,CAAC,0BAAY,aAAa,CAAC","file":"Input.scss","sourcesContent":[".root{width:100%;position:relative}.root input{width:100%}.root input:global(.error){font-weight:normal;font-size:1.1rem}.root textarea.noResize{resize:none}.root.error{color:#db392e}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "Input__root",
	"noResize": "Input__noResize",
	"error": "Input__error"
};