import {combineReducers} from "redux";
import * as ActionTypes from "constants/ActionTypes";

const initialState = {
    status: null,
    error: null,
};

function signUp(state = initialState, action) {
    switch (action.type) {
        case ActionTypes.SIGNUP_USER_SUCCESS:
            return {
                ...state,
                status: action.status,
                error: null,
            };
        case ActionTypes.SIGNUP_USER_ERROR:
            return {
                ...state,
                error: action.error,
                status: action.status,
            };
        default:
            return state;
    }
}

export default combineReducers({
  signUp
});
